import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { PageLayoutStandard } from '@shapeable/ui';
import { SLIDESHOW_FADE } from '../../theme';

// -------- Styles -------->

const LayoutStyles = breakpoints({
  base: css`
    /* override title styles via BEM here to affect spacing */ 
    .shp--page-layout-standard__title {
      font-family: ${theme.FONT('sans')};
      font-size: ${18/15}em;
      margin-bottom: ${theme.UNIT(4)};
    }
    
    .shp--page-layout-standard__subtitle {
      font-family: ${theme.FONT('serif')};
      letter-spacing: -0.03em;
      font-size: ${32/15}em;
      line-height: 1.1em;
    }

  `,
  tablet: css`
    .shp--page-layout-standard__title {
      font-size: ${20/15}em;
    }

    .shp--page-layout-standard__subtitle {
      letter-spacing: -0.03em;
      font-size: ${44/15}em;
    }

  `,
  desktop: css`
    .shp--page-layout-standard__title {
      font-size: ${24/15}em;
    }

    .shp--page-layout-standard__subtitle {
      letter-spacing: -0.03em;
      font-size: ${54/15}em;
    }
  `
});

// -------- Components -------->

const My = {
  Layout: styled(PageLayoutStandard)`${LayoutStyles}`,
};

export const PageLayoutDefault: PageLayoutComponent = (props) => {
  const { className, entity, slices } = props;
  
  return (
    <My.Layout 
      headerProps={{ showSlideControls: false, fadeColor: SLIDESHOW_FADE }}
      entity={entity}
      slices={slices}
      className={className}
    />
  )
};
