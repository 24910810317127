import React from 'react';
import styled, { css } from 'styled-components';
import { PageLayoutComponent } from '@shapeable/types';
import { breakpoints, theme } from '@shapeable/theme';
import { PageLayoutStandard } from '@shapeable/ui';
import { SLIDESHOW_FADE } from '../../theme';

// -------- Styles -------->

const LayoutStyles = breakpoints({
  base: css`
    /* override title styles via BEM here to affect spacing */ 
    .shp--page-layout-standard__title {
      font-size: ${36/15}em;
      letter-spacing: -0.03em;
    }
  `,
  tablet: css`
    .shp--page-layout-standard__title {
      font-size: ${90/15}em;
    }
  `,
  desktop: css`
    .shp--page-layout-standard__title {
      font-size: ${112/15}em;
    }
  `
});

// -------- Components -------->

const My = {
  Layout: styled(PageLayoutStandard)`${LayoutStyles}`,
};

export const PageLayoutHome: PageLayoutComponent = (props) => {
  const { className, entity, slices } = props;
  
  return (
    <My.Layout 
      headerProps={{ showSlideControls: false, fadeColor: SLIDESHOW_FADE }}
      entity={entity}
      slices={slices}
      className={className}
    />
  )
};
