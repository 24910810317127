import { 
  createSliceLayout,
  SliceImageBlocks,
  SliceIntro,
  SlicePersonBioGrid,
  SlicePersonBioGridProps,
} from '@shapeable/ui';

export const SliceLayoutKeyPillars = createSliceLayout(
  SliceImageBlocks, {
    verticalPadding: 'small',
  }
);

export const SliceLayoutIntro = createSliceLayout(
  SliceIntro, {
    verticalPadding: 'medium',
  }
);

export const SliceLayoutPeople = createSliceLayout<SlicePersonBioGridProps>(
  SlicePersonBioGrid, {
    verticalPadding: 'small',
    props: {
      transform: '',
    }
  }
); 