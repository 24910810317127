import React from 'react';
import { Classable, HasChildren } from '@shapeable/types';
import styled, { css } from 'styled-components';
import { breakpoints, theme } from '@shapeable/theme';
import { SiteFooterLayout, LayoutShell, UiConfig, UiProvider } from '@shapeable/ui';
import * as pageLayouts from './page-layouts';
import * as sliceLayouts from './slice-layouts';
import { LANG_STRINGS } from '../lang';
import { themeOverrides, LayoutStyles, RHODIAN_BLUE } from '../theme';


export type LayoutPropTypes = Classable & HasChildren & {
};

const LayoutDefaultProps: LayoutPropTypes = {
};

// -------- Styles -------->

const ContainerStyles = breakpoints({
  base: css`
    ${LayoutStyles};
  `,
});

const FooterStyles = breakpoints({
  base: css`
  `,
});

const BodyStyles = breakpoints({
  base: css`
    flex-grow: 1;
  `,
});


// -------- Components -------->

const My = {
  Container: styled(LayoutShell)`${ContainerStyles}`,
  Body: styled.div`${BodyStyles}`,
    Footer: styled(SiteFooterLayout)`${FooterStyles}`,

};

export const Layout: React.FC<LayoutPropTypes> = (props) => {
  const { className, children } = props;

  const config: UiConfig = {
    layouts: { pageLayouts, sliceLayouts, defaultPageLayout: pageLayouts.PageLayoutDefault },
    langStrings: LANG_STRINGS,
    theme: themeOverrides,
    header: {
      variant: 'overlay',
    },
    componentProps: {
      SiteMenuBar: {
        colors: {
          background: 'dark',
          text: 'light',
          dark: {
            background: 'dark',
            text: 'light',
          }
        },
      },
      PageHeader: {
        themeMode: 'dark',
      },
      NavigationMenuItem: {
        colors: {
          hover: {
            background: RHODIAN_BLUE,
            text: 'light',
          },
        }
      },
      MenuItemLink: {
        colors: {
          hover: {
            text: RHODIAN_BLUE,
          },
        }
      },
      MenuItem: {
        colors: {
          hover: {
            text: 'light',
            background: RHODIAN_BLUE,
          },
        }
      },
    }
  }

  return (
    <UiProvider value={config}>
      <My.Container
        className={className}
        includeTooltip
        tooltipProps={{
          backgroundColor: '#FFFFFF',
        }}
      >
        <My.Body>
          {children}
        </My.Body>

        <My.Footer backgroundColor='#FFFFFF' />
      </My.Container>
    </UiProvider>
  );
  
  
};

Layout.defaultProps = LayoutDefaultProps;

